@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;700;900&display=swap");

body,
html {
  margin: 0;
  font-family: "Poppins";
  scroll-behavior: smooth;
}

img {
  height: auto;
  max-width: 100%;
}

/* .banner {
  width: 100%;
  height: 100vh;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
} */

.logo-holder {
  position: absolute;
  top: 15px;
  left: 15px;
}

.desktop-banner-container > img {
  //max-height: 100vh;
  width: 100%;
  //object-fit: cover;
  //object-position: center center;
}
.mobile-banner-container > img {
  height: 100vh;
  width: 100%;
  object-fit: cover;
  object-position: center 70%;
}


.info2 {
  padding: 50px;
  text-align: center;
}


.info1 {
  padding: 50px;
  text-align: center;
  font-weight: 700;
  margin-right: auto;
  margin-left: auto;
}

.heading-container {
  padding: 40px;
  text-align: center;
}

h2 {
  font-weight: bold;
  font-size: xx-large;
}

.info-holder {
  grid-template-columns: 1fr 1fr;
}


.info-column1 {
  padding: 70px;
  background-color: #7aa1ba;
  color: white;
  text-align: center;
}
.info-column2 {
  padding: 100px;
  background-color: #313e5b;
  text-align: center;
}
.info-column3 {
  padding: 30px 90px;
  background-color: #e5ecf1;
  color: black;
}
.info-column4 {
  background-color: #7aa1ba;
  position: relative;
}

ul {
  padding-left: 15px;
  color: #313e5c;
}

.next,
.previous {
  background-color: #7aa1ba;
  fill: #7aa1ba;
  border: #7aa1ba;
  cursor: pointer;
}
.slide-controls {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-top: 15%;
  z-index: 10;
}
.slideshow {
  width: auto;
  color: white;
  justify-content: center;
  position: relative;
}
.slide-container {
  display: flex;
  flex-direction: row;
  width: auto;
  align-items: center;
  position: relative;
}
.slide-content {
  padding-top: 5%;
  font-style: italic;
}
.slide-text {
  text-align: center;
  width: auto;
  position: absolute;
  //visibility: hidden;
  opacity: 0;
  transition: opacity 0.5s;
  //display: none;
}
.current-slide {
  visibility: visible;
  opacity: 1;
  transition: opacity 0.5s;
}
// .slide-text.current-slide {
//   display: block;
// }


.interest {
  background-color: #313e5b;
  color: white;
  padding: 30px;
  text-align: center;
}

.banner-logo {
  width: 160px;
}

.slogan {
  font-weight: 200;
  margin-top: 0;
  margin-bottom: 0;
}

footer {
  font-weight: bold;
  padding: 50px;
}

.cta {
  margin-bottom: 40px;
  background-color: rgb(52, 109, 150);
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: none;
  color: white;
  padding: 20px;
  text-align: center;
  display: inline-block;
}

.contact-button {
  background-size: 50px;
  padding: 4px 34px;
  background-color: #bdd0dd;
  text-decoration: none;
  color: #313e5b;
  font-weight: bold;
  cursor: pointer;
  transition-duration: 0.4s;
}
.contact-button:hover {
  transform: scale(0.9);
  background-color: #71aecd;
}



.grid-container {
  display: grid;
}
.grid-item {
  /*width: 110px;*/
  text-align: center;

  font-size: 15px;
}
.grid-item > svg {
  margin-top: 45px;
  width: 110px;
  height: auto;
  margin-bottom: 20px;
}

.icons-holder {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 100px;
  padding: 15px;
  gap: 15px;
}


.indicator-container {
  display: flex;
  justify-content: center;
  position: relative;
  margin-bottom: 50px;
}
.indicator {
  cursor: pointer;
}
.ind {
  background-color: #7aa1ba;
  border: #7aa1ba;
  color: white;
  padding: 4px;
}

.current-indicator svg {
  fill: #313e5c;
}

.footer-logo {
  height: 30px;
  width: auto;
  bottom: 15px;
  fill: white;
}