// Breakpoints
$xsMinWidth: 576px;
$smMinWidth: 768px;
$mdMinWidth: 992px;
$lgMinWidth: 1200px;
$xlMinWidth: 1400px;

$xsMaxWidth: 575.98px;
$smMaxWidth: 767.98px;
$mdMaxWidth: 991.98px;
$lgMaxWidth: 1199.98px;
$xlMaxWidth: 1399.98px;

@media (max-width: 1315px) {
  .icons-holder {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media screen and (min-width: $xlMinWidth) {
  .slogan {
    font-size: 35px;
  }
}
// @media only screen and (max-height: 650px) {
//   .slogan {
//     font-size: 25px;
//   }
// }
@media only screen and (max-width: $xlMaxWidth) and (min-width: $lgMinWidth) {
  .slogan {
    font-size: 30px;
  }
}
@media only screen and (max-width: $lgMaxWidth) and (min-width: $mdMinWidth) {
  .slogan {
    font-size: 25px;
  }
}
@media only screen and (max-width: $mdMaxWidth) {
  .slogan {
    color: white;
    font-size: 20px;
  }
}

@media only screen and (max-width: $mdMaxWidth) {
  .info-column2 {
    padding: 20px;
  }
}

// @media only screen and (min-height: 650px) {
//   .desktop-banner {
//     max-height: 100vh;
//     object-fit: cover;
//     object-position: center center;
//   }
// }
@media screen and (max-width: $mdMaxWidth) {
  .banner-logo {
    fill: white;
  }
  .desktop-banner-container {
    display: none;
  }
  .mobile-banner-container {
    display: block;
  }
}
@media screen and (min-width: $mdMinWidth) {
  .banner-logo {
    fill: black;
  }
  .desktop-banner-container {
    display: block;
  }
  .mobile-banner-container {
    display: none;
  }
}

@media (max-width: $mdMaxWidth) {
  .info-holder {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: $smMinWidth) {
  .info1 {
    width: 50%;
  }
}

@media screen and (min-width: $mdMinWidth) {
  .info2 {
    margin-left: auto;
    margin-right: auto;
    width: 65%;
  }
}

@media (max-width: $mdMaxWidth) {
  .info2 {
    padding: 15px;
  }
  .info-column3 {
    padding: 15px;
  }
}

@media (max-width: 630px) {
  .icons-holder {
    grid-template-columns: 1fr 1fr;
  }
}

@media (min-width: $xsMinWidth) {
  .slide-text {
    padding: 7%;
  }
}

@media only screen and (max-width: $mdMaxWidth) {
  .info-column1 {
    padding: 30px;
  }
}